export const NotFoundPage = () => {
  return (
    <div>
      <h1>You probably shouldn't be here...</h1>
      <div class="try-links">
        <h2>Try:</h2>
        <h3>
          <a href="/">Home</a>
          <br />
          <a href="/driving">Driving</a>
        </h3>
      </div>
    </div>
  );
};
